const footerFixed = $('.l-footer_fixed');
const totopBtn = $('.l-footer_toTop');
const footer = $('.l-footer');

$(window).on('scroll',function() {
    var sc = $(this).scrollTop();

    //フッターお問い合わせ固定に伴うズレの解消

      if($(window).width() > 768 && $(window).width() < 1230 && !footerFixed.hasClass('is-absolute')){
        var scrollLeft = $(this).scrollLeft();
        footerFixed.children('.container').css('margin-left',-scrollLeft);
      } else  {
        footerFixed.children('.container').css('margin-left','auto');
      }

    if(sc > 300){
      if(footerFixed.is(':hidden')) footerFixed.fadeIn();
    }else{
      if(!footerFixed.is(':hidden')) footerFixed.fadeOut();
    }
    //フッターお問い合わせ固定

    if(sc + $(window).height() >=　footer.offset().top){
      if(!footerFixed.hasClass('is-absolute')){
         footerFixed.addClass('is-absolute').children('.container').css('margin-left','auto');
       }

    }else{
      if(footerFixed.hasClass('is-absolute'))  footerFixed.removeClass('is-absolute');
    }
});

//戻るボタン
$('.l-footer_toTop,.toTop').on('click',function(){
  $('html,body').animate({scrollTop: 0},1200);
});

//アコーディオン
$('.p_title').on('click',function(){
  var content = $(this).next('.p_body')
  if(!content.is(':animated')) content.is(':hidden') ? content.slideDown(): content.slideUp();
});

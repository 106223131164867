
const popupBtn = $('.popup');
//戻るボタン
popupBtn.on('click',function(){
  content = $(this).next('.popup_wrap');
  if(content.is(':hidden') && !content.is(':animated')){
     content.children('.popup_item').prepend('<div class="popup_close"></div>')
     content.find('.popup_content').prepend('<button  class="closeBtn"></button>')
     content.fadeIn()
     content.addClass('is-show')
   }else{
     content.fadeOut()
     $('.closeBtn').remove()
     $('.popup_close').remove()
   }
});
$(document).on('click','.popup_close',function(){
  if(!$(this).parents('.popup_wrap').is(':animated')){
    $(this).parents('.popup_wrap').fadeOut()
    $('.popup_close').remove()
    $('.closeBtn').remove()
  }
});
$(document).on('click','.closeBtn',function(){
  if(!$('.popup_wrap').is(':animated')){
    $('.popup_wrap').fadeOut()
    $('.popup_close').remove()
    $('.closeBtn').remove()
  }
});

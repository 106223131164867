var timer = null;
const LAZYIMG = document.getElementById('isLazyload');
if(LAZYIMG != null){
  img = LAZYIMG.getElementsByTagName('img')

  if(img.length > 0){
    function lazyLoad(x){
      for (var i = 0; i < img.length; i++) {
        if(img[i].getAttribute('data-src') != null){
          var c = '';
          if(window.pageYOffset + window.parent.screen.height - x > img[i].getBoundingClientRect().top){
            img[i].src = img[i].getAttribute('data-src');
          }
        }
      }
    }
    function delayAddClass(x){
      for (var i = 0; i < img.length; i++) {

        if(img[i].getAttribute('data-src') != null){
          var c = '';
          if(window.pageYOffset + window.parent.screen.height - x > img[i].getBoundingClientRect().top){
            if(img[i].src != img[i].getAttribute('data-src')){
              img[i].src = img[i].getAttribute('data-src');
            }
            if(img[i].getAttribute('class') != ''){
              c = img[i].getAttribute('class').replace(/is-hidden/,'is-view');
            }else{
              c = 'is-view';
            }
            img[i].setAttribute('class',c);
            img[i].removeAttribute('data-src');
          }else{

            break;
          }
        }
      }
    }

    //スクロールで画像読み込み
    window.addEventListener( 'scroll', function(){
      lazyLoad(300);
      clearTimeout( timer );
    	timer = setTimeout(function() {
        delayAddClass(300);
      }, 200 );
    }, false );
  }
}
